@import url(bulma.min.css);

@media (min-width: 769px) {
  html {
    scroll-snap-type: y mandatory;
  }

  section {
    scroll-snap-align: center;
  }
}

html {
  scroll-behavior: smooth;
}

.linkedin {
  color: #4a4a4a;
}

.linkedin:hover {
  color: #0077b5;
}

.icon-text {
  font-family: inherit !important;
  line-height: 1.5rem !important;
}

path,
line {
  stroke-width: 10px;
  fill: transparent;
}

path {
  stroke-linecap: round;
}